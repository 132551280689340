var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import CityTown from "@/components/form/CityTown.vue";
import LogoHeroEdit from "../components/LogoHeroEdit.vue";
export const option = {
    showAlert: true,
};
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.merchant = null;
        this.data = null;
    }
    mounted() {
        if (option.showAlert && !this.id)
            this.alert('新增的門市會套用現有以及未來申請的支付', '新增門市', 'warning');
        option.showAlert = true;
        this.load();
    }
    get title() {
        return this.id ? "編輯門市" : "新增門市";
    }
    copy() {
        this.data.Name = this.merchant.BrandName;
        this.data.Contact.Phone = this.merchant.LiaisonContact.Phone;
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            const tasks = [
                post("/api/Merchant/Edit").then(v => this.merchant = v),
            ];
            if (this.id) {
                tasks.push(post("/api/Merchant/GetOutlet", { Id: this.id }).then(v => this.data = v));
            }
            else {
                // Vue2 當中沒有利用 Proxy 來捕捉 setter，
                // 所以一開始必須要先填入 undefined，對應的欄位才會是反應式的
                this.data = {
                    MerchantId: '',
                    Name: '',
                    ShortName: '',
                    Description: '',
                    Contact: {
                        Phone: '',
                        Address: '',
                        TownId: '',
                        Facebook: undefined,
                        Instagram: undefined,
                        TikTok: undefined,
                        GoogleMap: undefined,
                        Longitude: undefined,
                        Latitude: undefined,
                    },
                    UseGPS: true,
                    Hero: [],
                    Logo: [],
                    Advertisement: [],
                    ContractDate: undefined,
                    SellDate: undefined,
                    OpenDate: undefined,
                    Remark: '',
                };
            }
            yield Promise.all(tasks);
            if (!this.id) {
                this.data.MerchantId = this.merchant.Id;
            }
            else if (!this.data.Contact.Longitude) {
                // 如果過去沒有填過經緯度，立刻試著填入
                this.addressChange();
            }
        });
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            yield post('/api/Merchant/EditOutlet', Object.assign({}, this.data));
            this.$router.back();
        });
    }
    addressChange() {
        return __awaiter(this, void 0, void 0, function* () {
            const coor = yield this.getCoordiantes();
            if (coor) {
                const contact = this.data.Contact;
                contact.Longitude = coor.Longitude;
                contact.Latitude = coor.Latitude;
            }
        });
    }
    getGoogleLink() {
        return __awaiter(this, void 0, void 0, function* () {
            const coor = yield this.getCoordiantes();
            if (coor) {
                const url = yield post('/api/Merchant/GetShortURL', { Id: coor.Id });
                const contact = this.data.Contact;
                contact.GoogleMap = url;
            }
        });
    }
    getCoordiantes() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const contact = (_a = this.data) === null || _a === void 0 ? void 0 : _a.Contact;
            if (!contact || !contact.Address)
                return;
            const cityTown = this.$refs.town.toString(contact.TownId);
            if (!cityTown)
                return;
            const address = cityTown + contact.Address;
            try {
                return yield post('/api/Merchant/GetCoordinates', { Id: address });
            }
            catch (e) {
                return undefined;
            }
        });
    }
    GoogleInfo() {
        this.alert('<div class="text-left"><ol class="pl-3"><li>開啟 Google 地圖 App，查找出門市。</li><li>點選「分享」，然後複製連結資訊。</li></ol></div>', "取得 Google<br>地圖連結");
    }
};
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
default_1 = __decorate([
    Component({ components: { CityTown, LogoHeroEdit } })
], default_1);
export default default_1;
