export var MerchantType;
(function (MerchantType) {
    MerchantType[MerchantType["Normal"] = 0] = "Normal";
    MerchantType[MerchantType["PaymentApplicant"] = 1] = "PaymentApplicant";
    MerchantType[MerchantType["Appropriator"] = 2] = "Appropriator";
    MerchantType[MerchantType["ClawOutletOwner"] = 4] = "ClawOutletOwner";
})(MerchantType || (MerchantType = {}));
export var FeatureStatus;
(function (FeatureStatus) {
    FeatureStatus[FeatureStatus["NotApplied"] = 0] = "NotApplied";
    FeatureStatus[FeatureStatus["Applying"] = 1] = "Applying";
    FeatureStatus[FeatureStatus["Activated"] = 2] = "Activated";
    FeatureStatus[FeatureStatus["Deactivated"] = -1] = "Deactivated";
})(FeatureStatus || (FeatureStatus = {}));
export var PersonType;
(function (PersonType) {
    PersonType[PersonType["Legal"] = 0] = "Legal";
    PersonType[PersonType["Natural"] = 1] = "Natural";
})(PersonType || (PersonType = {}));
